import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OfficeService extends GlobalService {

  constructor(private _http: HttpClient) {
    super(_http);
    this.serviceUrl = 'office';
  }
}
